import React, { FC, ReactNode, useMemo } from 'react'
import { FaTag, FaVial, FaWineGlassAlt } from 'react-icons/fa'
import { ListItem, ListItemText } from '@material-ui/core'
import { ListItemProps } from '@material-ui/core/ListItem'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { GatsbyLinkProps, Link } from 'gatsby'
import prettyMs from 'pretty-ms'

import { KnownAsset } from 'api_supplimental'
import useAssetImages from 'hooks/useAssetImages'
import { assetIsRecentlySampled } from 'utils/asset-utils'
import { getBlobImageUrl } from 'utils/remote-image-utils'

import IconPETE from '!!svg-react-loader!./img/Resin-identification-code-1-PETE.svg' //eslint-disable-line

export type Props = Omit<GatsbyLinkProps<any>, 'to' | 'ref' | 'onClick'>
  & Omit<ListItemProps<'a'>, 'dense' | 'component' | 'button' | 'className' | 'onClick'>
  & {
    asset: KnownAsset,
    iconRight?: ReactNode
    onClick?: (e: React.MouseEvent, asset: KnownAsset) => void
  }

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    color: theme.palette.text.primary,
    padding: 0,
    paddingRight: theme.spacing(1),
    lineHeight: 1,
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  thumbnail: {
    flex: '0 0 50px',
    alignSelf: 'stretch',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    marginRight: theme.spacing(1),
  },
  icons: {
    display: 'inline-block',
    '& svg': {
      marginLeft: theme.spacing(1 / 2),
    },
  },
}))

export const AssetListItem: FC<Props> = (props: Props) => {
  const {
    asset,
    iconRight,
    onClick,
    ...passedProps
  } = props
  const assetId = asset.id
  const styles = useStyles({ hasTag: asset.hasTag })
  const images = useAssetImages(assetId)

  const handleClick = (e: any): void => {
    if(typeof onClick === 'function') {
      onClick(e, asset)
    }
  }
  const $bottleType = useMemo(() => {
    if(asset.fluid) {
      if(asset.fluid.bottleType === 'pete') {
        return <IconPETE height="16px" width="16px" />
      }
      if(asset.fluid.bottleType === 'glass') {
        return <FaWineGlassAlt />
      }
    }
  }, [asset.fluid])

  const $icons = useMemo(() => {
    if(iconRight) {
      return (
        <span className={styles.icons}>
          {iconRight}
        </span>
      )
    }
    return (
      <span className={styles.icons}>
        {assetIsRecentlySampled(asset) && <FaVial />}
        {$bottleType}
        {asset.hasTag && <FaTag />}
      </span>
    )
  }, [$bottleType, asset, iconRight, styles.icons])

  const ForwardedLink = React.forwardRef<HTMLAnchorElement, Omit<GatsbyLinkProps<any>, 'ref'>>(
    (itemProps, ref) => (
      <Link innerRef={ref} {...itemProps} onClick={handleClick} />
    )
  )

  const sampledAgo = useMemo(() => {
    if(asset.dateLastSampled) {
      return prettyMs((new Date().getTime() - new Date(asset.dateLastSampled).getTime()), { compact: true })
    }
  }, [asset.dateLastSampled])

  const $thumbnail = useMemo(() => {
    const imageUrl = images && images.length > 0 && images[0].imagePath && getBlobImageUrl(images[0].imagePath, 'tn')
    if(imageUrl) {
      return (
        <span
          style={{ backgroundImage: `url(${imageUrl})` }}
          className={styles.thumbnail}
        />
      )
    }
    return <span className={styles.thumbnail} />
  }, [images, styles.thumbnail])

  return (
    <ListItem
      {...passedProps}
      onClick={handleClick}
      dense
      component={ForwardedLink}
      to={`/app/assets/${assetId}`}
      className={styles.root}
    >
      {$thumbnail}
      <ListItemText primary={asset.name} secondary={sampledAgo ? `Last sampled ${sampledAgo} ago` : 'Never sampled'} />
      {$icons}
    </ListItem>
  )
}

export default AssetListItem

import { RECENT_SAMPLE_AGE } from 'app_constants'

import { KnownAsset } from 'api_supplimental'

export function assetIsRecentlySampled(asset: KnownAsset): boolean {
  if(asset.dateLastSampled) {
    const age = Date.now() - asset.dateLastSampled.getTime()
    return age < RECENT_SAMPLE_AGE
  }
  return false
}

import { DependencyList, useContext, useEffect, useMemo } from "react";
import LocalAssetsContext from "contexts/LocalEntities/Assets/context";
import { NumberParam, StringParam } from "use-query-params";

import { AssetTagLinkDTO } from "api";
import { KnownAsset, Result } from "api_supplimental";
import { IApiState } from "hooks/useApi";
import useApiFromUrlParam from "hooks/useApiFromUrlParam";

import { HistoryUpdateMode } from "./useSampleLink";

type TReturn = {
  tagIdentifier?: string;
  setTagIdentifier: (id?: string, updateType?: HistoryUpdateMode) => void;
  asset?: KnownAsset;
  assetId?: number;
  setAssetId: (id?: number | string, updateType?: HistoryUpdateMode) => void;
  setEquipmentID: (
    equipmentID?: string | string,
    updateType?: HistoryUpdateMode
  ) => void;
  assetFetchState: IApiState<KnownAsset | undefined>;
  equipmentFetchState: IApiState<KnownAsset | undefined>;
  tagLinksFetchState: IApiState<Result<AssetTagLinkDTO>>;
  tagIsLinked?: boolean;
  tagIsLinkedOutsideCustomerContext?: boolean;
};
export default function useAsset(deps?: DependencyList): TReturn {
  const [tagLinksFetchState, setTagIdentifier, tagIdentifier] =
    useApiFromUrlParam<Result<AssetTagLinkDTO>, string>(
      "tag",
      (_tagIdentifier) => `v1/tags/${_tagIdentifier}/asset-tag-links`,
      undefined,
      StringParam,
      { dependencies: deps }
    );
  const [assetFetchState, setAssetId, assetId] = useApiFromUrlParam<
    KnownAsset,
    number
  >("asset", (_assetId) => `v1/assets/${_assetId}`, undefined, NumberParam, {
    dependencies: deps,
  });
  const [equipmentFetchState, setEquipmentID, equipmentID] = useApiFromUrlParam<
    KnownAsset,
    string
  >(
    "equipmentID",
    (id) => `v1/assetsByModel?equipmentID=${id}`,
    undefined,
    StringParam,
    {
      dependencies: deps,
    }
  );

  const assetsContext = useContext(LocalAssetsContext);

  const setAssetIdFromStringOrNumber = (
    _assetId: string | number | undefined,
    updateType: "replace" | "replaceIn" | "push" | "pushIn" | undefined
  ): void => {
    setAssetId(
      typeof _assetId === "string" ? parseInt(_assetId) : _assetId,
      updateType
    );
  };

  const tagLink = useMemo<AssetTagLinkDTO | undefined>(() => {
    if (!tagLinksFetchState.data) {
      return undefined;
    }
    const links = tagLinksFetchState.data && tagLinksFetchState.data.data;
    return links?.find((l) => l.active);
  }, [tagLinksFetchState.data]);

  const tagIsLinked = useMemo<boolean | undefined>(() => {
    if (tagIdentifier && tagLinksFetchState.statusCode === 200) {
      return typeof tagLink !== "undefined";
    }
  }, [tagIdentifier, tagLink, tagLinksFetchState.statusCode]);

  const tagIsLinkedOutsideCustomerContext = useMemo<boolean | undefined>(() => {
    if (!tagIdentifier || !assetsContext.assets) return;
    const allAssetTags = assetsContext.assets
      .filter((a) => a.tagIdentifier)
      .map((a) => a.tagIdentifier);
    return tagIsLinked && !allAssetTags.includes(tagIdentifier);
  }, [assetsContext.assets, tagIdentifier, tagIsLinked]);

  useEffect(() => {
    if (
      tagIdentifier &&
      tagLink &&
      tagLink.assetId &&
      !tagIsLinkedOutsideCustomerContext
    ) {
      setAssetId(tagLink.assetId, "replaceIn");
    }
  }, [setAssetId, tagIdentifier, tagIsLinkedOutsideCustomerContext, tagLink]);

  useEffect(() => {
    if (assetId) {
      setTagIdentifier(undefined, "replaceIn");
    }
  }, [assetId, setTagIdentifier]);

  useEffect(() => {
    if (equipmentID && equipmentFetchState.data && !equipmentFetchState.error) {
      setAssetIdFromStringOrNumber(equipmentFetchState.data.id, "replaceIn");
    }
  }, [equipmentFetchState, equipmentID, equipmentFetchState.data]);

  const asset = assetFetchState.data || equipmentFetchState.data;

  return {
    tagIdentifier,
    setTagIdentifier,
    assetFetchState,
    equipmentFetchState,
    tagLinksFetchState,
    tagIsLinked,
    tagIsLinkedOutsideCustomerContext,
    assetId,
    setAssetId: setAssetIdFromStringOrNumber,
    setEquipmentID,
    asset,
  };
}

import Intercept from 'contexts/api/Intercept'
import InterceptedResponse from 'contexts/api/InterceptedResponse'
import Debug from 'debug'

import { SampleDTO } from 'api'
import { LocalSample, SampleLinkSansStatus } from 'api_supplimental'
import { Transaction } from 'idb/Transactions'

import { updateAssetSampleHistory } from '../updateAssetSampleHistory'

const log = Debug('AL:intercept:putSample')

export const putSample = new Intercept<SampleDTO | LocalSample | SampleLinkSansStatus>({
  method: 'PUT',
  pathname: 'v1/samples',
  fetch: async (bag) => {
    const {
      entitiesDb,
      transactionsContext: {
        instance: tx,
      },
      fetcher,
      networkState,
      req,
      opts,
    } = bag
    if(!opts || !opts.body) {
      return new InterceptedResponse({ status: 400 })
    }
    const body = JSON.parse(opts.body as string)
    const newSample: Partial<LocalSample> = {
      identifier: body.bottleIdentifier,
      ...body,
    }
    const sampleKey = await entitiesDb.Samples.put(newSample)
    if(networkState.online) {
      return fetcher(req, opts)
    }
    const data = await entitiesDb.Samples.get(sampleKey)
    if(!tx) {
      throw new Error('Transactions database context is required!')
    }
    await tx.Queue.add(new Transaction(req, opts))
    return new InterceptedResponse({ data, status: 201 })
  },
  postParse: async bag => {
    const {
      entitiesDb,
      parsedResponse,
    } = bag
    if(parsedResponse) {
      const {
        assetId,
      } = parsedResponse
      const dto = parsedResponse as SampleDTO
      const newSample: LocalSample = {
        ...dto,
        sampledAt: dto.sampledAt ? new Date(dto.sampledAt) : undefined,
      }
      if(!assetId) {
        throw new Error('Sample requires an asset ID')
      }
      const asset = await entitiesDb.Assets.get(assetId.toString())
      if(!asset) {
        throw new Error('Sample\'s asset could not be found')
      }
      await entitiesDb.Samples.put(newSample)
      log(`Updating asset sample history for asset #${assetId}`)
      await updateAssetSampleHistory(entitiesDb, assetId.toString())
    }
  },
})


import { BottleDTO } from 'api'

import Intercept from '../Intercept'
import InterceptedResponse from '../InterceptedResponse'

export const getBottle = new Intercept<Partial<BottleDTO>>({
  pathname: 'v1/bottles/:identifier',
  fetch: async ({ entitiesDb, match, fetcher, networkState, req, opts }) => {
    const identifier = match && match.params && match.params.identifier
    if(networkState.online) {
      return fetcher(req, opts)
    }
    if(!identifier) {
      return new InterceptedResponse({ status: 404 })
    }
    const data = await entitiesDb.Samples.get({ identifier: identifier })
    if(data) {
      return new InterceptedResponse({
        data: {
          identifier,
          status: 3,
        },
        status: 200,
      })
    }
    return new InterceptedResponse({ status: 404 })
  },
})

import Intercept from "../Intercept";

import { deleteImage } from "./deleteImage";
import { deleteSample } from "./deleteSample";
import { getAddress } from "./getAddress";
import { getAsset, getAssetByEquipmentID } from "./getAsset";
import { getAssetManufacturers } from "./getAssetManufacturers";
import { getAssetsAll } from "./getAssetsAll";
import { getAssetSamples } from "./getAssetSamples";
import { getAssetTagLinks } from "./getAssetTagLinks";
import { getAssetTypes } from "./getAssetTypes";
import { getBottle } from "./getBottle";
import { getContact } from "./getContact";
import { getContacts } from "./getContacts";
import { getCustomerPlants } from "./getCustomerPlants";
import { getCustomers } from "./getCustomers";
import { getPlant } from "./getPlant";
import { getPlantDetail } from "./getPlantDetail";
import { getPortTypes } from "./getPortTypes";
import { getSample } from "./getSample";
import { getTagAsset } from "./getTagAsset";
import { getTestPackages } from "./getTestPackages";
import { getUsersMe } from "./getUsersMe";
import { patchAddresses } from "./patchAddresses";
import { patchAsset } from "./patchAsset";
import { patchAssets } from "./patchAssets";
import { patchAssetTagLink } from "./patchAssetTagLink";
import { patchContacts } from "./patchContacts";
import { patchPlant } from "./patchPlant";
import { patchPlantAddressLinks } from "./patchPlantAddressLinks";
import { patchPlantContactLinks } from "./patchPlantContactLinks";
import { patchSample } from "./patchSample";
import { postAssetTagLinks } from "./postAssetTagLinks";
import { putAddresses } from "./putAddresses";
import { putAsset } from "./putAsset";
import { putAssetTagLink } from "./putAssetTagLink";
import { putContact } from "./putContact";
import { putImage } from "./putImage";
import { putPlantAddressLinks } from "./putPlantAddressLinks";
import { putPlantContactLinks } from "./putPlantContactLinks";
import { putSample } from "./putSample";

const intercepts: Intercept[] = [
  deleteImage,
  deleteSample,
  getAddress,
  getAsset,
  getAssetManufacturers,
  getAssetSamples,
  getAssetTagLinks,
  getAssetTypes,
  getAssetsAll,
  getBottle,
  getContact,
  getContacts,
  getCustomerPlants,
  getCustomers,
  getPlant,
  getPlantDetail,
  getPlantDetail,
  getPortTypes,
  getSample,
  getTagAsset,
  getTestPackages,
  getUsersMe,
  patchAddresses,
  patchAsset,
  patchAssetTagLink,
  patchAssets,
  patchContacts,
  patchPlant,
  patchPlantAddressLinks,
  patchPlantContactLinks,
  patchSample,
  postAssetTagLinks,
  putAddresses,
  putAsset,
  putAssetTagLink,
  putContact,
  putImage,
  putPlantAddressLinks,
  putPlantContactLinks,
  putSample,
  getAssetByEquipmentID,
];

export default intercepts;

import React, { FC } from 'react'
import { Button } from '@material-ui/core'
import { Link, RouteComponentProps } from '@reach/router'

import { KnownAsset, Result } from 'api_supplimental'
import AppHeader from 'components/AppHeader'
import PaddedFlexCentered from 'components/PaddedFlexCentered'
import Spinner from 'components/Spinner'
import useApi from 'hooks/useApi'
import { sortByProp } from 'utils/sortByProp'

import FilterableAssetList from './FilterableAssetList'

export const AssetsList: FC<RouteComponentProps> = () => {
  // const { assets } = useContext(offlineAssetsContext)
  const {
    data,
    isLoading,
  } = useApi<Result<KnownAsset>>('v1/assets/all')
  const assets = data && data.data && sortByProp(data.data, 'name')

  if(isLoading) {
    return (
      <PaddedFlexCentered>
        <Spinner />
      </PaddedFlexCentered>
    )
  }

  return (
    <>
      <AppHeader
        title="Assets"
        actionRight={<Button component={Link} to="new">New</Button>}
        subtitle={assets && `${assets.length} total`}
        fixed
      />
      <FilterableAssetList assets={assets} />
    </>
  )
}

export default AssetsList

import React, { HTMLAttributes, ReactNode, useMemo } from "react";
import { FaSadTear } from "react-icons/fa";
import { MdCameraAlt, MdHourglassEmpty } from "react-icons/md";
import { Fab, FormControl, InputLabel, Select, Zoom } from "@material-ui/core";
//@ts-ignore
import clsx from "clsx";

import InlineError from "components/Errors/InlineError";

import useQRAndBarcodeScanner, {
  Config as ScannerConfig,
  ScannerType,
} from "./useQRAndBarcodeScanner";
import { basicJsonRegex } from "utils/regex-library";

const styles = require("./ActiveScanner.css");

export type Props = {
  onBottleScan?: (bottleId: string) => void;
  onEquipmentIDScan?: (equipmentID: string) => void;
  onTagScan?: (assetId: string) => void;
  onScanResultCleared?: () => void;
  debug?: boolean;
  interval?: number;
  showCapturePhotoButton?: Boolean;
  capturePhotoIsLoading?: Boolean;
  onCapturePhotoImageDataURL?: (imageDataURL: string) => void;
  scanners?: ScannerType[];
} & HTMLAttributes<HTMLDivElement>;

export type AssetLinkQRCodeDataBottle = {
  Version: string;
  BottleID: string;
};
export type AssetLinkQRCodeDataTag = {
  Version: number;
  AssetIdentifier: string;
};
export type AssetLinkQRCodeData =
  | AssetLinkQRCodeDataBottle
  | AssetLinkQRCodeDataTag;

export const ActiveScanner = (props: Props) => {
  const {
    onBottleScan,
    onTagScan,
    onScanResultCleared,
    interval,
    showCapturePhotoButton,
    onCapturePhotoImageDataURL,
    capturePhotoIsLoading,
    className,
    children,
    scanners,
    onEquipmentIDScan: onEquipmentIDScanResult,
    ...passedProps
  } = props;

  const scannerConfig: Partial<ScannerConfig> = {
    onScanResult: handleScan,
    onScanResultCleared,
    scanners: scanners,
  };
  if (interval) {
    scannerConfig.interval = interval;
  }

  const {
    videoRef,
    overlayCanvasRef,
    nativeSizeCanvasRef,
    // debugCanvasRef,
    videoDevices: { activeDevice, availableDevices, setSelectedVideoDeviceId },
    error,
    getImageDataURL,
  } = useQRAndBarcodeScanner(scannerConfig);

  function handleTakePhotoButtonClick() {
    const imageDataURL = getImageDataURL();
    if (imageDataURL && onCapturePhotoImageDataURL) {
      onCapturePhotoImageDataURL(imageDataURL);
    }
  }

  function handleScan(scanData: string) {
    try {
      const isJson = basicJsonRegex.test(scanData);
      //If is not a json object, consider this to be the raw machine name or equipment ID .
      if (
        !isJson &&
        onEquipmentIDScanResult &&
        typeof onEquipmentIDScanResult === "function"
      ) {
        onEquipmentIDScanResult(scanData);
        return;
      }

      const data: AssetLinkQRCodeData = JSON.parse(scanData);
      if ((data as AssetLinkQRCodeDataBottle).BottleID) {
        if (typeof onBottleScan === "function") {
          console.log("Handling bottle scan");
          onBottleScan((data as AssetLinkQRCodeDataBottle).BottleID);
        }
      } else if ((data as AssetLinkQRCodeDataTag).AssetIdentifier) {
        if (typeof onTagScan === "function") {
          console.log("Handling tag scan");
          onTagScan((data as AssetLinkQRCodeDataTag).AssetIdentifier);
        }
      }
    } catch (err) {
      console.warn(err);
    }
  }

  const $videoDeviceSelector: ReactNode = useMemo(() => {
    const handleSelectedDeviceChange = (
      e: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
      }>
    ) => {
      setSelectedVideoDeviceId(e.target.value as string);
    };
    if (activeDevice && availableDevices && availableDevices.length > 1) {
      return (
        <div className={styles.videoDeviceSelect}>
          <FormControl color="secondary" variant="filled">
            <InputLabel htmlFor="video-device-select">Camera</InputLabel>
            <Select
              native
              value={activeDevice.deviceId}
              onChange={handleSelectedDeviceChange}
              color="secondary"
              inputProps={{
                name: "video-device",
                id: "video-device-select",
              }}
            >
              {availableDevices.map((dev, i) => {
                const label =
                  dev.label.length === 0 ? `Camera ${i + 1}` : dev.label;
                return (
                  <option key={dev.deviceId} value={dev.deviceId}>
                    {label}
                  </option>
                );
              })}
            </Select>
          </FormControl>
        </div>
      );
    }
  }, [activeDevice, availableDevices, setSelectedVideoDeviceId]);

  if (error) {
    if (error.name === "NotAllowedError") {
      return (
        <div className={clsx(className, styles.error)} {...passedProps}>
          <span>Camera permission denied.</span>
          <FaSadTear />
        </div>
      );
    }
    console.warn("An unhandled scanning error occurred:", error);
    return <InlineError message="An error occurred in the QR scanner" />;
  }

  return (
    <div className={clsx(className, styles.root)} {...passedProps}>
      <canvas ref={nativeSizeCanvasRef} className={clsx(styles.hiddenCanvas)} />
      <video ref={videoRef} className={styles.video} />
      {/* <canvas ref={debugCanvasRef} className={clsx(styles.debugCanvas)} /> */}
      <canvas ref={overlayCanvasRef} className={clsx(styles.canvas)} />
      {$videoDeviceSelector}
      <Zoom key="capture" in={!!showCapturePhotoButton} unmountOnExit>
        <Fab
          color="primary"
          aria-label="Upload a photo for this asset"
          className={styles.fab}
          onClick={handleTakePhotoButtonClick}
        >
          {capturePhotoIsLoading ? (
            <MdHourglassEmpty style={{ fontSize: "1.5rem" }} />
          ) : (
            <MdCameraAlt style={{ fontSize: "1.5rem" }} />
          )}
        </Fab>
      </Zoom>
      {children}
    </div>
  );
};

export default ActiveScanner;

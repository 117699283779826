import React from 'react'

import { SampleDTO } from 'api'
import { Result } from 'api_supplimental'
import EmptyBucket from 'components/EmptyBucket'
import FetchStateWrapper from 'components/FetchStateWrapper'
import JQUIList from 'components/JQUIList'
import useApi from 'hooks/useApi'

import SampleHistoryItem from './SampleHistoryItem'

type Props = {
  assetId?: string
}

export const SampleHistory = (props: Props) => {
  const { assetId } = props
  const fetchState = useApi<Result<SampleDTO>>(`v1/assets/${assetId}/samples`)
  const samples = (fetchState.data && fetchState.data.data) || []
  return (
    <FetchStateWrapper
      fallback={<EmptyBucket><small>No sample history for this asset</small></EmptyBucket>}
      fetchState={fetchState}
      pad
    >
      <JQUIList>
        {samples.map(sample => <SampleHistoryItem sample={sample} key={sample.identifier} />)}
      </JQUIList>
    </FetchStateWrapper>
  )
}

export default SampleHistory

import React, { useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Button } from '@material-ui/core'
import { RouteComponentProps } from '@reach/router'
import SnackbarProvider from 'contexts/SnackbarProvider'
import { useSnackbar } from 'notistack'

import { AssetTagLinkDTO } from 'api'
import { KnownAsset } from 'api_supplimental'
import AppHeader from 'components/AppHeader'
import AssetDetail from 'components/Asset/AssetDetail'
import FourOhFour from 'components/Errors/FourOhFour'
import Modal from 'components/Modal'
import PaddedFlexCentered from 'components/PaddedFlexCentered'
import Spinner from 'components/Spinner'
import useApi from 'hooks/useApi'

import { DoRetagAsset } from './Hookers/DoRetagAsset'
import { DoTagAsset, TagAssetProps } from './Hookers/DoTagAsset'
import AssetImages from './AssetImages'
import ModifyAsset from './Modify'
import TagAssetModal from './TagAssetModal'

type Props = {
  assetId?: string
}

export const Asset = (props: RouteComponentProps<Props>) => {
  if(!props.assetId) {
    throw new Error('No assetId provided')
  }
  const assetId = props.assetId

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  // Controls modify modal state
  const [isModify, setModify] = useState<boolean>(false)
  // Controls scanner modal state
  const [isTagAssetModalOpen, setIsTagAssetModalOpen] = useState<boolean>(false)
  // The scanned tagIdentifier. Used to trigger API calls.
  const [nextTagIdentifier, setNextTagIdentifier] = useState<string>()
  // The TagId returned after a successful update. Used to refetch the AssetDTO when link changes.
  const [updatedTagIdentifier, setUpdatedTagIdentifier] = useState<string>()
  const [updatedAsset, setUpdatedAsset] = useState<KnownAsset>()
  const [imageChangeCount, setImageChangeCount] = useState<number>(0)
  const [assetDetails, setAssetDetails] = useState<KnownAsset>()

  const {
    data: fetchedAssetDetails,
    error,
    isLoading,
    sendCount,
  } = useApi<KnownAsset>(`v1/assets/${assetId}`, undefined, { dependencies: [updatedAsset, updatedTagIdentifier, imageChangeCount] })

  useEffect(() => {
    if(fetchedAssetDetails) {
      setAssetDetails(fetchedAssetDetails)
    }
  }, [fetchedAssetDetails])

  if(error) {
    throw error
  }

  const isNextTagDifferent = (nextTagIdentifier && assetDetails) ? nextTagIdentifier !== assetDetails.tagIdentifier : false

  useEffect(() => {
    if(nextTagIdentifier && assetDetails && !isNextTagDifferent) {
      enqueueSnackbar('This tag is already linked to this asset.', { key: 'same-tag', preventDuplicate: true })
    } else {
      closeSnackbar('same-tag')
    }
  }, [assetDetails, closeSnackbar, enqueueSnackbar, isNextTagDifferent, nextTagIdentifier])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleModifyClick = () => {
    setModify(true)
  }

  const handleSubmitted = (asset: KnownAsset) => {
    setUpdatedAsset(asset)
    setModify(false)
  }

  const handleModifyCancel = () => {
    setModify(false)
  }

  const handleTagAssetClick = () => {
    setIsTagAssetModalOpen(true)
  }

  const handleCloseTagAssetModal = () => {
    setIsTagAssetModalOpen(false)
  }

  const handleTagScanned = (tagIdentifier: string) => {
    setNextTagIdentifier(tagIdentifier)
  }

  const handleScanCleared = () => {
    setNextTagIdentifier(undefined)
  }

  const handleLinkSuccess = (assetTagLink: AssetTagLinkDTO) => {
    setIsTagAssetModalOpen(false)
    setUpdatedTagIdentifier(assetTagLink.tagIdentifier)
    setNextTagIdentifier(undefined)
  }

  const handleImagesChanged = () => {
    setImageChangeCount(imageChangeCount + 1)
  }
  const $body = useMemo(() => {
    if(!assetDetails) {
      if(!isLoading && sendCount > 0) {
        return (
          <FourOhFour message="Asset not found" />
        )
      }
      if(isLoading) {
        return (
          <PaddedFlexCentered>
            <Spinner />
          </PaddedFlexCentered>
        )
      }
    }
    return (
      <>
        <AssetImages asset={assetDetails} isLoading={isLoading} />
        <AssetDetail asset={assetDetails} onTagAssetClick={handleTagAssetClick} />
      </>
    )
  }, [assetDetails, isLoading, sendCount])

  const $hooker = (() => {
    if(!assetDetails || !nextTagIdentifier || !isNextTagDifferent) return null
    const hookerProps: TagAssetProps = {
      tagIdentifier: nextTagIdentifier,
      asset: assetDetails,
      onSuccess: handleLinkSuccess,
    }
    if(assetDetails.tagIdentifier) {
      return <DoRetagAsset {...hookerProps} />
    }
    return <DoTagAsset {...hookerProps} />
  })()

  const title: string = (assetDetails && assetDetails.name) ? assetDetails.name : '...'
  return (
    <>
      <AppHeader
        title={title}
        actionRight={<Button type="button" onClick={handleModifyClick}>Modify</Button>}
        fixed
      />
      <Helmet title={`Asset Details: ${title}`} />
      {$body}
      <Modal id="tagAssetModal" show={isTagAssetModalOpen}>
        <SnackbarProvider anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={5000} disableWindowBlurListener>
          {$hooker}
          <TagAssetModal
            title={assetDetails && assetDetails.tagIdentifier ? 'Retag Asset' : 'Tag Asset'}
            subtitle={(assetDetails && assetDetails.name) ? assetDetails.name : undefined}
            onClose={handleCloseTagAssetModal}
            onTagScan={handleTagScanned}
            onScanResultCleared={handleScanCleared}
          />
        </SnackbarProvider>
      </Modal>
      <Modal id="modifyAsset" show={isModify}>
        <SnackbarProvider>
          {assetDetails && (
            <ModifyAsset
              asset={assetDetails}
              onCancel={handleModifyCancel}
              onSubmitted={handleSubmitted}
              onImagesChanged={handleImagesChanged}
            />
          )}
        </SnackbarProvider>
      </Modal>
    </>
  )
}

export default Asset

/* eslint-disable filenames/match-exported */
import React, { FormEvent, useEffect, useMemo, useState } from "react";
import { FaMapMarkerAlt, FaPrescriptionBottle } from "react-icons/fa";
import { Button } from "@material-ui/core";
import { Link } from "gatsby";
import { useSnackbar } from "notistack";

import { NewSampleDTO, SampleDTO } from "api";
import { KnownAsset } from "api_supplimental";
import AppHeader from "components/AppHeader";
import DoImageUpload from "components/Asset/Modify/DoImageUpload";
import Modal from "components/Modal";
import PaddedFlexCentered from "components/PaddedFlexCentered";
import Linkage, { LinkageMember } from "components/Sample/Linkage";
import SuggestedNextAsset from "components/Sample/SuggestedNextAsset";
import SiteHeadMetadata from "components/SiteHeadMetadata";
import Spinner from "components/Spinner";
import { Vibrate } from "components/Vibrate";
import { useBeep } from "hooks/useBeep";
import { useTimeout } from "hooks/useTimeout";
import { dataURLtoBlob } from "utils/blob-utils";

import AssetSelectCreateAssetTagLink from "./AssetSelect/AssetSelectCreateAssetTagLink";
import InnerAsset from "./Linkage/InnerAsset";
import InnerBottle from "./Linkage/InnerBottle";
import AssetSelect from "./AssetSelect";
import BottleIdInput from "./BottleIdInput";
import ConfirmChangeAsset from "./ConfirmChangeAsset";
import ConfirmUnlink from "./ConfirmUnlink";
import SampleForm from "./SampleForm";
import Scanner from "./Scanner";
import TagOutsideCustomerWarning from "./TagOutsideCustomerWarning";
import UnlinkedTagWarning from "./UnlinkedTagWarning";
import { useSampleLink } from "./useSampleLink";
import useSuggestedNextAction from "./useSuggestedNextAction";

const styles = require("./Sample.css");

export const Sample = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [showUnlinkConfirmation, setShowUnlinkConfirmation] =
    useState<boolean>(false);
  // If the tag should be automatically populated when a sample is scanned and tagIdentifier is not defined
  const [populateTagFromSample, setPopulateTagFromSample] =
    useState<boolean>(true);
  const [vibrate, setVibrate] = useState<boolean>(false);
  const [imageToUpload, setImageToUpload] = useState<File | null>(null);
  const beep = useBeep();
  const {
    assetId,
    tagIdentifier,
    bottleIdentifier,
    asset,
    sample,
    isLinkConflict,
    bodyMode,
    entryMode,
    tagIsLinked,
    fetchStates,
    fn: {
      reset,
      resetBottle,
      resetAsset,
      resetEquipment,
      resetTag,
      setAssetId,
      setEquipmentID,
      setBottleIdentifier,
      setBottleIdToDelete,
      setEntryMode,
      setSampleAssetChangeConfirmed,
      setSampleToPatch,
      setTagIdentifier,
      setLinkedAssetId,
    },
  } = useSampleLink({ populateTagFromSample });

  const { nextAssetSuggestion } = useSuggestedNextAction();

  /****************************************************************************
   * SIDE EFFECTS
   ***************************************************************************/

  // watch for sample put failures and try patching instead
  useEffect(() => {
    if (fetchStates.samplePut.error) {
      enqueueSnackbar(
        <div>
          <span>Error while creating sample:</span>
          <div>
            <code>{fetchStates.samplePut.error.message}</code>
          </div>
        </div>,
        { variant: "error" }
      );
    }
  }, [enqueueSnackbar, fetchStates.samplePut.error]);

  useEffect(() => {
    handleEquipmentIDSearchFailure();
  }, [
    enqueueSnackbar,
    fetchStates.equipment.error,
    fetchStates.equipment.statusCode,
  ]);

  useTimeout(
    () => {
      setVibrate(false);
    },
    vibrate ? 1000 : null
  );

  /****************************************************************************
   * EVENT HANDLERS
   ***************************************************************************/

  function handleEquipmentIDSearchFailure() {
    if (!fetchStates.equipment.error) return;

    const notFound = fetchStates.equipment.statusCode === 404;
    if (notFound) {
      enqueueSnackbar(
        <div>
          <span>Asset Not Found</span>
          <br />
          <code>{fetchStates.equipment.error?.message}</code>
        </div>,
        { variant: "warning", autoHideDuration: 5000 }
      );

      return;
    }

    const conflict = fetchStates.equipment.statusCode === 409;
    if (conflict) {
      enqueueSnackbar(
        <div>
          <span>Multiple Assets found</span>
          <br />
          <code>{fetchStates.equipment.error?.message}</code>
          <br />
          <code>Please use the asset selector to select your asset.</code>
        </div>,
        { variant: "error", autoHideDuration: 5000 }
      );

      return;
    }

    enqueueSnackbar(
      <div>
        <span>Equipment ID Load Failed</span>
        <div>
          <code>{fetchStates.equipment.error?.message}</code>
        </div>
      </div>,
      { variant: "error", autoHideDuration: 5000 }
    );
  }

  const handleUnlickClick = () => {
    setShowUnlinkConfirmation(!showUnlinkConfirmation);
  };

  const handleUnlinkConfirm = () => {
    if (sample && sample.identifier) {
      setBottleIdToDelete(sample.identifier);
    }
    setShowUnlinkConfirmation(false);
  };

  const handleUnlinkCancel = () => {
    setShowUnlinkConfirmation(false);
  };

  const handleConfirmChangeAssetCancel = () => {
    if (sample && sample.assetId) {
      setAssetId(sample.assetId);
    } else {
      console.warn("No sample tagIdentifier to change to");
    }
  };

  const handleConfirmChangeAsset = () => {
    setSampleAssetChangeConfirmed(true);
  };

  const handleFormReset = (e: FormEvent<any>) => {
    e.preventDefault();
    setPopulateTagFromSample(false);
    reset();
  };

  const handleFormSubmit = (e: FormEvent<any>) => {
    setPopulateTagFromSample(false);
    e.preventDefault();
  };

  const handleFormSubmitted = (newSample: SampleDTO) => {
    if (!bottleIdentifier || !assetId) {
      throw new Error(
        "Cannot patch a Sample without a bottleIdentifier and tagIdentifier."
      );
    }
    const sampleLink: Partial<NewSampleDTO> = newSample;
    sampleLink.identifier = bottleIdentifier;
    sampleLink.assetId = assetId;
    sampleLink.sampledAt = new Date().toJSON();
    sampleLink.sampleSourceRating = Number(newSample.sampleSourceRating);
    setPopulateTagFromSample(false);
    setSampleToPatch(sampleLink as NewSampleDTO);
  };

  const handleBottleScanned = (scannedBottleId: string) => {
    setPopulateTagFromSample(true);
    setBottleIdentifier(scannedBottleId, "pushIn");
    beep();
    setVibrate(true);
    try {
      if ("FS" in window) {
        window.FS.event("Scanned Bottle", {
          bottleIdentifier_str: scannedBottleId,
        });
      }
    } catch (err) {
      console.warn(err);
    }
  };

  const handleTagScanned = (scannedTagId: string) => {
    setTagIdentifier(scannedTagId, "pushIn");
    beep();
    setVibrate(true);
    try {
      if ("FS" in window) {
        window.FS.event("Scanned Tag", {
          tagIdentifier_str: scannedTagId,
        });
      }
    } catch (err) {
      console.warn(err);
    }
  };

  const handleEquipmentIDScanned = (equipmentID: string) => {
    // setTagIdentifier(scannedTagId, "pushIn");
    setEquipmentID(equipmentID, "pushIn");
    //select the asset.
    beep();
    setVibrate(true);
    try {
      if ("FS" in window) {
        window.FS.event("Scanned Barcode", {
          barcodeData_str: equipmentID,
        });
      }
    } catch (err) {
      console.warn(err);
    }
  };

  const handleSuggestedNextAssetClick = (asset: KnownAsset) => {
    setAssetId(asset.id);
  };

  const handleClearAsset = () => {
    setPopulateTagFromSample(false);
    resetAsset();
    resetEquipment();
    resetTag();
  };

  const handleClearBottle = () => {
    resetBottle();
  };

  const handleCapturePhoto = (imageData: string): void => {
    const blob = dataURLtoBlob(imageData);
    const file = new File([blob], "asset-image.png");
    setImageToUpload(file);
  };

  const handleImageUploadComplete = () => {
    enqueueSnackbar("Asset photo uploaded", { variant: "success" });
    setImageToUpload(null);
  };

  /****************************************************************************
   * RENDERERS
   ***************************************************************************/

  const memberAsset = (
    <LinkageMember
      icon={<FaMapMarkerAlt />}
      hasValue={!!assetId}
      hasError={
        fetchStates.asset.isError ||
        fetchStates.tag.isError ||
        tagIsLinked === false
      }
      onDelete={handleClearAsset}
    >
      {entryMode === "asset" ? (
        <Button type="button" onClick={() => setEntryMode(undefined)}>
          Cancel
        </Button>
      ) : (
        <InnerAsset
          assetFetchState={fetchStates.asset}
          tagLinksFetchState={fetchStates.tag}
          tagIdentifier={tagIdentifier}
          tagIsLinked={
            tagIdentifier && tagIdentifier !== "" ? tagIsLinked : undefined
          }
          onManualEntry={() => setEntryMode("asset")}
        />
      )}
    </LinkageMember>
  );
  const memberBottle = (
    <LinkageMember
      icon={<FaPrescriptionBottle />}
      hasValue={!!bottleIdentifier}
      onDelete={handleClearBottle}
    >
      {entryMode === "bottle" ? (
        <BottleIdInput
          onCancel={() => {
            setEntryMode(undefined);
          }}
          onChange={(newBottleId) => {
            setEntryMode(undefined);
            setPopulateTagFromSample(true);
            setBottleIdentifier(newBottleId, "pushIn");
          }}
        />
      ) : (
        <InnerBottle
          bottleIdentifier={bottleIdentifier}
          onManualEntry={() => setEntryMode("bottle")}
        />
      )}
    </LinkageMember>
  );

  const $body = (() => {
    switch (bodyMode) {
      case "loading": {
        return (
          <PaddedFlexCentered>
            <Spinner />
          </PaddedFlexCentered>
        );
      }
      case "sampleAssetConflict": {
        if (sample && sample.assetId) {
          return (
            <ConfirmChangeAsset
              sampleAssetId={sample.assetId}
              onCancel={handleConfirmChangeAssetCancel}
              onConfirm={handleConfirmChangeAsset}
            />
          );
        } else {
          console.warn(
            "Trying to confirm an sample asset change with no sample"
          );
        }
        return;
      }
      case "sampleError":
      case "form":
        return (
          <SampleForm
            sampleFetchState={fetchStates.sample}
            key="sampleform"
            onSubmit={handleFormSubmitted}
            asset={asset}
          />
        );
      case "scan":
        return (
          <Scanner
            className={styles.scanner}
            onBottleScan={handleBottleScanned}
            onTagScan={handleTagScanned}
            onEquipmentIDScan={handleEquipmentIDScanned}
            interval={200}
            showCapturePhotoButton={!!asset}
            onCapturePhotoImageDataURL={handleCapturePhoto}
            capturePhotoIsLoading={!!imageToUpload}
          >
            <SuggestedNextAsset
              asset={
                nextAssetSuggestion && !assetId
                  ? nextAssetSuggestion.asset
                  : undefined
              }
              onClick={handleSuggestedNextAssetClick}
            />
          </Scanner>
        );
      case "unlinkedTag": {
        if (!tagIdentifier) return null;
        return (
          <UnlinkedTagWarning
            tagIdentifier={tagIdentifier}
            onLinkUnlinkTag={() => {
              setEntryMode("link-tag");
            }}
          />
        );
      }
      case "tagLinkedOutsideCustomer": {
        if (!tagIdentifier) return null;
        return (
          <TagOutsideCustomerWarning
            tagIdentifier={tagIdentifier}
            onClearButtonClick={handleClearAsset}
          />
        );
      }
      case "entry":
        if (entryMode === "asset") {
          return (
            <AssetSelect
              onChange={(asset) => {
                setEntryMode(undefined);
                setAssetId(asset.id, "pushIn");
              }}
              filterAssistiveText="Select an asset to link this sample to"
            />
          );
        } else if (entryMode === "link-tag") {
          if (!tagIdentifier) {
            console.warn("Attempt to link tag with no tagId.");
            return null;
          }
          return (
            <AssetSelectCreateAssetTagLink
              tagIdentifier={tagIdentifier}
              onChange={(newAssetId) => {
                setEntryMode(undefined);
                setLinkedAssetId(newAssetId);
              }}
            />
          );
        } else if (entryMode === "bottle") {
          return (
            <div className={styles.bottleEntryHint}>Enter a bottle number</div>
          );
        }
    }
    return <div>Unknown BodyMode: "{bodyMode}"</div>;
  })();

  const titleString = useMemo(() => {
    if (bodyMode === "unlinkedTag") {
      return "Unlinked tag";
    }
    if (
      bodyMode === "sampleAssetConflict" ||
      bodyMode === "tagLinkedOutsideCustomer"
    ) {
      return "🚨 Conflict 🚨";
    }
    if (asset && bottleIdentifier) {
      return `Link Sample "${asset.name}" => "${bottleIdentifier}"`;
    }
    if (asset) {
      return `Link Sample "${asset.name}"`;
    }
    if (bottleIdentifier) {
      return `Sample bottle #${bottleIdentifier}`;
    }
    return "Link Samples";
  }, [asset, bottleIdentifier, bodyMode]);

  return (
    <div className={styles.root}>
      <div
        className={styles.main}
        onReset={handleFormReset}
        onSubmit={handleFormSubmit}
      >
        <SiteHeadMetadata title={titleString} />
        <AppHeader
          title="Sample"
          actionLeft={
            bodyMode !== "form" ? (
              <Button type="button" component={Link} to="/app/assets">
                Done
              </Button>
            ) : undefined
          }
          actionRight={
            assetId || tagIdentifier || bottleIdentifier ? (
              <Button type="reset" onClick={handleFormReset}>
                Clear
              </Button>
            ) : undefined
          }
          fixed
        />
        {asset && asset.id && imageToUpload && (
          <DoImageUpload
            file={imageToUpload}
            assetId={asset.id}
            onComplete={handleImageUploadComplete}
          />
        )}
        <Linkage
          asset={memberAsset}
          bottle={memberBottle}
          isLinked={!!sample && !fetchStates.sample.isError}
          onUnlink={handleUnlickClick}
          isLoading={
            fetchStates.sample.isLoading ||
            fetchStates.samplePut.isLoading ||
            fetchStates.sampleDelete.isLoading
          }
          isLinkConflict={isLinkConflict}
        />
        {$body}
        <Modal
          show={showUnlinkConfirmation}
          id="confirmUnlink"
          overflowTargetSelector="#sample-form-overflow"
          className={styles.confirmModal}
        >
          <ConfirmUnlink
            onConfirm={handleUnlinkConfirm}
            onCancel={handleUnlinkCancel}
          />
        </Modal>
        {vibrate ? <Vibrate /> : null}
      </div>
    </div>
  );
};

export default Sample;

export function quickStringHash(s: string): number {
  return s.split("").reduce((a, b) => {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);
}

export function paramsfromURL(url: string) {
  const index = url.indexOf("?");
  const subs = index > 0 ? url.slice(index) : url;
  return new URLSearchParams(subs);
}

import React, { useState } from 'react'
import useTimeAgo from '@rooks/use-time-ago'

import { SampleDTO } from 'api'
import JQUIListItemLink from 'components/JQUIList/JQUIListItemLink'

const styles = require('./SampleHistoryItem.css')

const dateFormatter = new Intl.DateTimeFormat(undefined, {
  year: 'numeric',
  month: 'short',
  day: '2-digit',
})

const SampleHistoryItem = (props: { sample: SampleDTO }) => {
  const {
    sample: {
      assetId,
      identifier: bottleIdentifier,
      sampledAt,
    },
  } = props
  const [didWarnInvalidDate, setDidWarnInvalidDate] = useState<boolean>()
  const dateSampled = sampledAt ? new Date(sampledAt) : undefined
  if(!dateSampled && !didWarnInvalidDate) {
    console.warn('This sample has an invalid dateSampled:', props.sample)
    setDidWarnInvalidDate(true)
  }
  // @ts-ignore
  const timeAgo = useTimeAgo(dateSampled, { intervalMs: 1000 })
  const href = (assetId && bottleIdentifier) ? `/app/sample?asset=${encodeURIComponent(assetId)}&bottle=${encodeURIComponent(bottleIdentifier)}` : undefined
  const $time = dateSampled ? (
    <time dateTime={dateSampled.toJSON()} className={styles.time}>
      <span>{dateFormatter.format(dateSampled)}</span>
      <small>{timeAgo}</small>
    </time>
  ) : (
    <span className={styles.time}>Prehistoric</span>
  )
  return (
    <JQUIListItemLink linkProps={{ to: href }}>
      <div className={styles.row}>
        {$time}
        <small><code>{bottleIdentifier}</code></small>
      </div>
    </JQUIListItemLink>
  )
}

export default SampleHistoryItem
